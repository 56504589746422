import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://ajhh5uckgk.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Oscar Health',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/a80bd627-77bd-4235-27dc-bd37c8d01f00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#4f50ff',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
